document.addEventListener("DOMContentLoaded", function(event) {
    // rewrite 404 link to the WP homepage
    link404 = document.querySelectorAll('.dt-404page a');
    if ( link404.length ) {
        link404[0].setAttribute('href','javascript:void(0)');
        link404[0].onclick = function() {
            window.top.location.href = '/';
        }
    }
});

$( document ).ready(function() {
    var WEBOPAC_DOMAIN = window.location.origin;

    var kirkiInlineStyles = $('#kirki-inline-styles', window.top.document).html();
    $('head').append( '<style>' + kirkiInlineStyles + '</style>' );
    var kirkiInlineStylesPalette = $('#kirki-inline-styles-palette', window.top.document).html();
    $('head').append( '<style>' + kirkiInlineStylesPalette + '</style>' );

    $(window).click(function() {
        $('.dt-popover').hide();
    });
     
    // hide #loginBox 
    $('#loginBox').hide();
    $( '#popUpButtonLogon' ).hide();
    $('<input id="popUpButtonLogon_wp" class="btn btn-primary" type="button" value="Anmelden" style="width:10em">').insertAfter('#popUpButtonLogon');

    // rewrite reservation links ( location: list )
    $('#DivGridviewResult .reservationWrapper.loginButton').each(function(){
        $(this).click(function(){
            var hrefUrl = $(this).attr('href');
            var dataQuery = hrefUrl.split("data=");
            $('<input id="data_wp" type="hidden" value="' + dataQuery[1] + '">').insertAfter('#popUpButtonLogon');
        });
    });
    // rewrite reservation links ( location: details )
    $('#DetailWrapper .reservationWrapper.loginButton').each(function(){
        $(this).click(function(){
            var hrefUrl = $(this).attr('href');
            var dataQuery = hrefUrl.split("data=");
            $('<input id="data_wp" type="hidden" value="' + dataQuery[1] + '">').insertAfter('#popUpButtonLogon');
        });
    });
    // rewrite Permalink - Merkliste
    var permalinkMerkliste = $('#PermalinkPanel pre').html();
    if (typeof permalinkMerkliste !== "undefined") {
        var dataQuery = permalinkMerkliste.split("data=");
        $('#PermalinkPanel pre').html( WEBOPAC_DOMAIN + '/suchen?data=' + dataQuery[1] );
    }


    // popup login ( location: result list page )
    $( '#popUpButtonLogon_wp' ).on( "click", function() {
        var targetForm = $('#loginBox');    
        
        var username = $(targetForm).find( $("[name*=username]") );
        var password = $(targetForm).find( $("[name*=password]") );
        var data_wp  = $(targetForm).find( $("#data_wp") );
        $.ajax({
            type: "POST",
            url: WEBOPAC_DOMAIN + '/webopac/service/UserService.ashx',
            data: ({
                action:     'userlogin',
                user:       $(username).val(),
                password:   $(password).val()
            }),
            contentType: "application/x-www-form-urlencoded; charset=UTF-8",
            success: function (r) {
                if ( r == 'ok' ) {
                    Cookies.set('logged', '1', { expires: 1, path: '/' });
                    window.top.location.href = '/overview/?action=reservate&data=' + $(data_wp).val();
                } else {
                    $('#errorMessage').html(r).show();
                }
            },
            error: function (r) {
                alert(r.responseText);
            },
            failure: function (r) {
                alert(r.responseText);
            }
        });        
      });

    // rewrite email share link // results list
    function rewriteShareEmail( idTarget, splitBy, baseURL ) {
        var emailShare       = $('#' + idTarget );
        var emailShareHref   = $(emailShare).attr('href');
        if (typeof emailShareHref !== "undefined") {
            var emailShareSplit = emailShareHref.split( splitBy );
            emailShare.attr('href', emailShareSplit[0] + WEBOPAC_DOMAIN + baseURL + emailShareSplit[1] )
        }
    }
    rewriteShareEmail( 'ctl00_ContentPlaceHolderMain_resultList_searchOptionView_HyperLinkRecommentImage', '/webopac/search.aspx', '/suchen' );
    rewriteShareEmail( 'ctl00_ContentPlaceHolderMain_HyperLinkRecommentImage', WEBOPAC_DOMAIN + 'webopac/detail.aspx', '/details' );

    // rewrite permalink
    var permaLink = $('#PermalinkWrapper textarea');
    var permaLinkVal   = $(permaLink).val();
    if (typeof permaLinkVal !== "undefined") {
        var permaLinkSplit = permaLinkVal.split('Id=');
        $(permaLink).html( WEBOPAC_DOMAIN + '/details/?Id=' + permaLinkSplit[1] );
        $('#SocialWrapper .socialItem').each(function() {
            var socialItemHref = $( this ).attr('href');
            var socialItemHrefBaseUrl = socialItemHref.split('=https');
            $(this).attr('href', socialItemHrefBaseUrl[0] + '=' + WEBOPAC_DOMAIN + '/details/?Id=' + permaLinkSplit[1] );
        });
    }       
    // rewrite Vorbestellung links
    var navVorbestellung= $('.webopac-b-reservate .nav.nav-pills a');
    if (typeof navVorbestellung !== "undefined") {
        $( navVorbestellung ).each(function(){
            $(this).click(function( e ) {
                e.preventDefault();
                e.stopPropagation();                
                var navHref   = $(this).attr('href');
                var dataQuery = '';
                if ( navHref.search( 'search' ) > 0 ) {
                    var dataSearch = navHref.split('?data=');
                    var dataQuery = '?data=' + dataSearch[1];
                    window.top.location.href = '/suchen/' + dataQuery;                
                } else {
                    var webopacUrl = navHref.split('/webopac/user/');
                    var wpAction = webopacUrl[1].split('.');
                    if ( wpAction[0] == 'logout' ) {
                        window.top.location.href = '/logout';
                    } else {
                        window.top.location.href = '/overview/?action=' + wpAction[0];
                    }
                }
            
            });                

        }); 
    }
   

    var linkStandort = $('.cellMediaItemLocation a.locationLink'); 
    if (typeof linkStandort !== "undefined") {
        $(linkStandort).each(function(){
            $(this).hide();
            var linkStandortPopup = $('<a href="#"></a>').text('Details'),
            StandortPopup         = $(this).parent().find('.MediaItemLocation'); 
            $(this).after(linkStandortPopup);
            $(StandortPopup).addClass('popover dt-popover');
            $(linkStandortPopup).click(function(e) {
                e.preventDefault();
                e.stopPropagation();
                if ( $(StandortPopup).is(':visible') ) {
                    
                    $(StandortPopup).hide();
                } else {    
                    $('.cellMediaItemLocation .dt-popover').hide();
                    $(StandortPopup).show();
                }
            });
        });
    } 


     
    $('#slide-details iframe#opaciframe_details', window.top.document).bind("load", function() {
        linksDetails = $('#detail-center a, #MediaItemLocationWrapper a, .MediaItemLocation.popover a');
        
        var iFrameWrapperHeight = $( '#slide-details', window.top.document).height();
        var iframe = window.top.document.getElementById('opaciframe_details');
        var iframeHeight = iframe.contentWindow.document.body.scrollHeight + 100;
        $('#loginBox').addClass('topLogin');
        $( '#slide-details .slide-content', window.top.document).height( iFrameWrapperHeight );  
        $( '#slide-details .slide-content iframe', window.top.document).height( iframeHeight );  
        
        // 'Vorbestellen' - details page
        $('.tableCopies .btn-reservation.loginButton').click(function( e ) {
            e.preventDefault();
            e.stopPropagation();
            $( '#black-overlay-v2', window.top.document ).attr( 'style', 'z-index:9998;');
            $('#btnLogin', window.top.document)[0].click();
            var hrefUrl = $(this).attr('href');
            var dataQuery = hrefUrl.split("data=");
            var wp_buttonLogon = $('.wp_buttonLogon', window.top.document);
            $('<input id="data_wp" type="hidden" value="' + dataQuery[1] + '">').insertAfter( wp_buttonLogon );
        });
        $('.tableCopies .btn-reservation').not( $('.loginButton') ).click(function( e ) {
            e.preventDefault();
            e.stopPropagation();
            var hrefUrl = $(this).attr('href');
            var dataQuery = hrefUrl.split("data=");
            window.top.location.href = '/overview/?action=reservate&data=' + dataQuery[1];
        });    

        $(linksDetails).each(function() {
            dataHref = $(this).attr('href');
            var dataDetails = dataHref.split("data=");
            if (typeof dataDetails[1] !== "undefined") {
                $(this).click(function(event){
                    event.preventDefault();
                    window.top.location.href = '/suchen/?data=' + dataDetails[1];
                });
            }
        });

     });

    // Leserkonto - Entleihungen ( bottom popup )
    var webopacVerlangernCheckbox = $('#ctl00_ContentPlaceHolderMain_BorrowDetailListExtendable .fatSelect input');
    $( webopacVerlangernCheckbox ).change(function(){
        checkVerlangernCheckbox(); 
    });   

    // Leserkonto - Vorbestellung ( Vorbestellung löschen )
    var webopacVorbestellungButtons = $('.webopac-b-reservations .mediaDetails .btn.btn-danger.noprint');
    if (typeof webopacVorbestellungButtons !== "undefined") {
        webopacVorbestellungPopup( webopacVorbestellungButtons );
    }
    function webopacVorbestellungPopup( webopacVorbestellungButtons ) {
        
        $( webopacVorbestellungButtons ).each(function() {
            $(this).click(function() {
                $('.modal-backdrop.fade').remove();
                var wrapperBorrowNextStep   = $( 'body', window.top.document  ).find('#wrapperBorrowNextStep');
                var popupVorbestellung = $('#removeReservationModal > div');
                $( wrapperBorrowNextStep ).html('');
                $( '<div class="popupwrapper"><div class="container m0"></div></div>' ).appendTo( $( wrapperBorrowNextStep ) );
                $( popupVorbestellung ).clone().appendTo( $( wrapperBorrowNextStep ).find('.popupwrapper > div') );
                $( wrapperBorrowNextStep ).find('.modal-header').remove();
                $( wrapperBorrowNextStep ).show();

                var removeBookButton = $( wrapperBorrowNextStep ).find('#modalReservationButtonOk');
                var dismissButton = $( wrapperBorrowNextStep ).find('button[data-dismiss="modal"]');
                $( removeBookButton ).on( 'click', function(){
                   $( popupVorbestellung ).find('#modalReservationButtonOk').trigger('click');
                   $( wrapperBorrowNextStep ).hide(); 
                });
                $( dismissButton ).on( 'click', function(){
                    $( wrapperBorrowNextStep ).html('');
                    $( wrapperBorrowNextStep ).hide(); 
                 });
                 
            })
        })
    }

    $('#ctl00_ContentPlaceHolderMain_searchPanel_HiddenFieldExpert').change(function(){
        $('.SearchPanelMain.search-wrapper').addClass('expert');
    });

    $( "#ctl00_ContentPlaceHolderMain_searchPanel_HiddenFieldExpert" ).on( "change", function() {
        $('.SearchPanelMain.search-wrapper').toggle('expert');
    });

    var userMenu = $('body').not('body.webopac-b-Register').not('body.webopac-b-register').find('#SubMenu .submenu-toolbar li');
    $(userMenu).find('a').each(function() {
        
        var hrefAttr = $(this).attr('href');
        $(this).click( function(e){
            e.preventDefault();
            e.stopPropagation(); 
            var webopacUrl = hrefAttr.split('.');
            if (typeof webopacUrl[0] !== "undefined") {
                if ( webopacUrl[0] == 'logout' ) {
                    window.parent.location = WEBOPAC_DOMAIN + '/logout';
                } else {    
                    // empty Verlängerung ausgewählt container
                    $('#wrapperBorrowNextStep',window.top.document).html('');                    
                    window.parent.location = WEBOPAC_DOMAIN + '/overview/?action=' + webopacUrl[0];
                }
            }            
        });
    });   

    $('#opaciframe', window.top.document).bind("load", function() {
        var tabSearch = $('#searchLinks');
        if ( tabSearch.length ) {
           $( tabSearch ).find('#linkSimple').click();
        }

        linksResultslist = $('.locationWrapperWrap a, .titleComplete a');
        $(linksResultslist).each(function() {
                dataHref = $(this).attr('href');
                if (typeof dataHref !== "undefined") {
                    var dataDetails = dataHref.split("data=");
                    if (typeof dataDetails[1] !== "undefined") {
                        $(this).click(function(event){
                            event.preventDefault();
                            window.top.location.href = '/suchen/?data=' + dataDetails[1];
                        });
                    }
                }
        });        

    });

    $('#opaciframe-acquisitions', window.top.document).bind("load", function() {
        $( '#webopac-new-acquisitions', window.top.document ).find( 'input[type=submit]' ).click(function() {
            $('.acquisition-buttons input').trigger('click');
        }); 
        linksResultslist = $('.locationWrapperWrap a, .titleComplete a');
        $(linksResultslist).each(function() {
                dataHref = $(this).attr('href');
                if (typeof dataHref !== "undefined") {
                    var dataDetails = dataHref.split("data=");
                    if (typeof dataDetails[1] !== "undefined") {
                        $(this).click(function(event){
                            event.preventDefault();
                            window.top.location.href = '/suchen/?data=' + dataDetails[1];
                        });
                    }
                }
        });         
    });

    $('#opaciframe-toplist', window.top.document).bind("load", function() {
        $( '#webopac-top-list', window.top.document ).find( 'input[type=submit]' ).click(function() {
            $('.toplist-buttons input').trigger('click');
        }); 
        linksResultslist = $('.locationWrapperWrap a, .titleComplete a');
        $(linksResultslist).each(function() {
                dataHref = $(this).attr('href');
                if (typeof dataHref !== "undefined") {
                    var dataDetails = dataHref.split("data=");
                    if (typeof dataDetails[1] !== "undefined") {
                        $(this).click(function(event){
                            event.preventDefault();
                            window.top.location.href = '/suchen/?data=' + dataDetails[1];
                        });
                    }
                }
        });          
    });

});

function checkVerlangernCheckbox() {
    var hasChecked = false;
    var checkboxVerlanger = $('#ctl00_ContentPlaceHolderMain_BorrowDetailListExtendable tbody .fatSelect input:checkbox');
    $( checkboxVerlanger ).each(function() {
        if ( $( this ).parent().find('input').is(':checked') ) {
            hasChecked = true;
        } 
    });   
    var wrapperBorrowNextStep   = $( 'body', window.top.document  ).find('#wrapperBorrowNextStep');
    var BorrowNextStep          = $( '#BorrowNextStep' );
    if ( hasChecked ) {
        $( wrapperBorrowNextStep ).html('');
        $( BorrowNextStep ).clone().appendTo( $( wrapperBorrowNextStep ) );
        $( wrapperBorrowNextStep ).show();             
        var weiterButton = $( wrapperBorrowNextStep ).find('#ctl00_ContentPlaceHolderMain_ButtonBorrowChecked');
        $(weiterButton).click(function() {
            $(this).attr('href','javascript:void(0);');
            __doPostBack('ctl00$ContentPlaceHolderMain$ButtonBorrowChecked','');
            $( wrapperBorrowNextStep ).hide();
        });
    } else {
        $( wrapperBorrowNextStep ).hide();
    } 
}

// rewrite details links // close/open slide
function closeSlide( currentUrl ) {
    $( '#slide-details', window.top.document ).removeClass('open');
    $('body', window.top.document).removeClass('scroll-off');
    $( '#black-overlay', window.top.document ).removeClass('on');
    $( '#slide-details .slide-content .iframe-content' , window.top.document ).html('');    
    window.top.history.pushState({ path: currentUrl }, '', currentUrl);
}
function openSlide( dataUrlEncoded, currentUrl ) {
    $( '#slide-details', window.top.document ).addClass('open');
    $( '#black-overlay', window.top.document ).addClass('on');
    $('body', window.top.document).addClass('scroll-off');
    $( '#slide-details .wrapper-loading', window.top.document ).show();
    var urlDetails = window.location.origin + '/details?popup=true&data=' + dataUrlEncoded;
    
    $( '#slide-details .slide-content .iframe-content' , window.top.document ).load( urlDetails, function( response, status, xhr ) {
        $( '#slide-details .wrapper-loading', window.top.document ).hide();
        var detailsUrl = window.top.location.protocol + "//" + window.top.location.host + '/?data=' + dataUrlEncoded + '&popup=true&share=true';
        if ( status == 'error' ) {
            var msg = 'Sorry but there was an error:';
            console.log( msg );
        }

    } );

    $( '#slide-details .slide-off, #black-overlay', window.top.document ).click(function() {
        closeSlide( currentUrl );
    });

}

var _rightMenu = $('.floatting-menu', window.top.document );
  
$( '#black-overlay', window.top.document ).on( 'click', function ( e ) { 
    if ( $( _rightMenu ).hasClass('open') ) {
        $( _rightMenu ).removeClass('open');
        $('html', window.top.document).toggleClass('scroll-off');
        $( '#black-overlay', window.top.document ).removeClass('on');        
    }
});

function slideDetailsPage() {
        linksThumb = $('.webopac-b-LendingHistory .thumbCoverImage, .ResultItem .thumbCoverImage').parent();
        links = $('.mediaDetails a, .btn-reservation.input-block-level, .coverWrapper a, .descriptionWrapper > a, .descriptionWrapper .description a, .widget-wrapper-book a, .flipster__item__content a');
        $(links).each(function() {
            var dataHref = $(this).attr('href');
            if (typeof dataHref !== 'undefined') {
                $(this).attr('href','javascript:void(0)'); 
                $(this).click(function() {

                    var dataDetails = dataHref.split('data=');
                    if (typeof dataDetails[1] !== 'undefined') {
                        dataIframe = dataDetails[1]; // data Url Encoded
                    }

                    var currentUrl = window.top.location.protocol + "//" + window.top.location.host + window.top.location.pathname + top.location.search;
                    var detailsUrl = window.top.location.protocol + "//" + window.top.location.host + '/?data=' + dataIframe + '&popup=true&share=true';
                    window.top.history.pushState({ path: detailsUrl }, '', detailsUrl);
                    openSlide( dataIframe, currentUrl );
    
                });
            }
        });
        $(linksThumb).each(function() {
            var dataHref = $(this).attr('href');
            if (typeof dataHref !== 'undefined') {
                $(this).attr('href','javascript:void(0)'); 
                $(this).click(function() {

                    var dataDetails = dataHref.split('data=');
                    if (typeof dataDetails[1] !== 'undefined') {
                        dataIframe = dataDetails[1]; // data Url Encoded
                    }

                    var currentUrl = window.top.location.protocol + "//" + window.top.location.host + window.top.location.pathname + top.location.search;
                    var detailsUrl = window.top.location.protocol + "//" + window.top.location.host + '/?data=' + dataIframe + '&popup=true&share=true';
                    window.top.history.pushState({ path: detailsUrl }, '', detailsUrl);
                    openSlide( dataIframe, currentUrl );
    
                });
            }
        });        
}

// register details page - slide    
var iFrameDOMContentBody = $( 'body', window.top.document  ).find('#details_page_setting');    
if ( iFrameDOMContentBody.attr('value') == 1 ) {
    $( document ).ready(function() {
        slideDetailsPage();
    });
}
 
$('.opaciframe-overview', window.top.document).bind("load", function() {
    var targetToScroll = $('#ContentMain'); 
    if (targetToScroll.length) {
        topScroll = (targetToScroll.offset().top );
        $('html, body', window.top.document).animate({ scrollTop: topScroll});
        
    }     
});    
 
$('.opaciframe-overview', window.top.document).bind("load", function() {
    var wpHiddenPaymentUrlField = $('#wpHiddenPaymentUrlField').val();
    if ( wpHiddenPaymentUrlField.length > 1 ) {
       //window.parent.location = wpHiddenPaymentUrlField;
       window.open( wpHiddenPaymentUrlField, '_blank') || window.parent.location.replace( wpHiddenPaymentUrlField );
    }
}); 