function webopacCatalogueSerach(iframeID, wrapperClassName, contentTabID, pageRedirectSlug, iframeTarget) {
    
    var contentTabHTML = '';
    
    var iFrameDOMContent = $('iframe#' + iframeID ).contents();
    var PanelSearchRow = iFrameDOMContent.find( '.' + wrapperClassName + ' .PanelSearchRow' );

    var webopacForm     = iFrameDOMContent.find('form');
    var actionFormUrl   = String($(webopacForm).attr('action'));

    if ( (typeof $(PanelSearchRow).attr('class') == 'undefined') && ((typeof webopacForm !== "undefined") && (actionFormUrl.indexOf("register.aspx") > 0)) ) { // Register menu
    
        PanelSearchRow = iFrameDOMContent.find( '.' + wrapperClassName + ' li span:not(.submenuseperator)' );
        $(PanelSearchRow).each(function() {
            var regsiterWP = $(this).clone(); 
            var registerHref        = $(regsiterWP).find('a');
            var registerWebopacURL  = $(registerHref).attr('href');
            var registerURL         = registerWebopacURL.split("?");
            $(registerHref).attr('href', '/' + pageRedirectSlug + '/?' + registerURL[1]);
            contentTabHTML = contentTabHTML + $(regsiterWP).html();
        });   
        
        $( '#' + contentTabID ).html( contentTabHTML );

    } else { // form search 
    
        var idWPBtn = 'wpBtn-' + contentTabID;
        var submitBtn = iFrameDOMContent.find( 'input[type=submit]' );
        var idSubmit = $(submitBtn).attr('id');
    
        if ( $( '#' + contentTabID ).is(':empty') ) {
        
            $(PanelSearchRow).each(function() {
                contentTabHTML =  contentTabHTML + $(this).html();  
            });
            $( '#' + contentTabID ).html( contentTabHTML );
            $('.toplist-buttons' ).remove();
            $(submitBtn).clone().appendTo( '#' + contentTabID ).attr('id', idWPBtn );
                
        }
        // on change - add the values to the webopac search
        var selectFields = iFrameDOMContent.find( 'select' );
        $(selectFields).each(function(){
            $( '#' + contentTabID + ' #' + $(this).attr('id') ).change(function(){
                var selectC1 = iFrameDOMContent.find('#' + $(this).attr('id') );
                $(selectC1).val( $(this).val() );
            });
        });

        $( '#' + idWPBtn ).click(function() {
            var submitBtnIframe = iFrameDOMContent.find('#' + idSubmit);
            $( submitBtnIframe )[0].click();
        });
        
        $('select').selectpicker();
        if ( iframeTarget == 'wpiframe') {
        //var iFrameDOMContentSearch = iFrameDOMContent.find('#webopac-new-acquisitions .opaciframe');
        var dataForm = iFrameDOMContent.find('#aspnetForm').attr('action');
        //var dataForm = $( iFrameDOMContentSearch ).attr('src');
            if ( dataForm ) {
                var dataQuery = dataForm.split('data=');
                if (typeof dataQuery[1] !== 'undefined') { // redirect to the WP page URL & pass the data encoded value
                    window.location.href = '/' + pageRedirectSlug + '/?data=' + dataQuery[1];
                }
            }
        }  
    }   
     
 }

 function webopacCatalogue( iframeID, wrapperClassName, contentTabID, pageRedirectSlug, iframeTarget ) {
    
    var iframeLoading = false;
    
    if ( $( '#' + contentTabID ).is(':empty') ) {
        $('iframe#' + iframeID ).bind('load', function() {
            var iFrameDOM       = $('iframe#' + iframeID).contents();
            var webopacForm     = iFrameDOM.find('form');
            var actionFormUrl   = String($(webopacForm).attr('action'));

            if ((typeof webopacForm !== "undefined") && (typeof actionFormUrl !== "undefined") ) {
                
                if (actionFormUrl.indexOf("detail.aspx") > 0) {
                    var iframeTargetSrc = $('#targetIframe').attr('src');
                    if (!iframeTargetSrc) {
                        $('#targetIframe').attr('src', WEBOPAC_DOMAIN + '/webopac/acquisitions.aspx?referer=MbaT87');
                    }
                    webopacCatalogueSerach( 'targetIframe', wrapperClassName, contentTabID, pageRedirectSlug, iframeTarget  );
                } else {
                    webopacCatalogueSerach( iframeID, wrapperClassName, contentTabID, pageRedirectSlug, iframeTarget  );    
                }
            }    
            iframeLoading = true;
            $('.card-body .wrapper-loading-v2').show();
            $( '.' + contentTabID ).hide();
            $( '.wrapper-loading' ).hide();
        });

    }
    
 }

 function searchFilterWP( iframeID, WEBOPAC_DOMAIN, PAGE_URL, ACTION, WRAPPER_ID ) {
    
    $('iframe#' + iframeID ).bind('load', function() {
        
        var iFrameDOMContent    = $('iframe#' + iframeID).contents();
        var webOPAC__filter     = iFrameDOMContent.find( '#' + WRAPPER_ID );

        var webOPAC__filter_toolBar = iFrameDOMContent.find( '#' + WRAPPER_ID + ' #FilterToolbar' );
        if ( typeof webOPAC__filter_toolBar !== 'undefined' ) {
            var webOPAC__filter_toolBarID = $(webOPAC__filter_toolBar).attr('id');
        }
        if ( typeof webOPAC__filter_toolBarID !== 'undefined' ) {
            $('#webOPAC__filter').show();
            $('#webOPAC__filter .webOPAC__filter_cnt').html( $(webOPAC__filter).html());
            $('#filtercontent').show();
            $('#filterDropDown').height('auto');
            $('#webOPAC__filter .dropdown-menu li a').each(function() {
                var hrefUrl = $(this).attr('href');
                var dataQuery = hrefUrl.split("data=");
                $(this).attr('href', WEBOPAC_DOMAIN + '/' + PAGE_URL + '/?data=' + dataQuery[1] + ( ACTION ? '&action=' + ACTION : '' ) );
            });  
            $('#webOPAC__filter .FilterPanelGroupWrapper a').each(function() {
                var hrefUrl = $(this).attr('href');
                var dataQuery = hrefUrl.split("data=");
                $(this).attr('href', WEBOPAC_DOMAIN + '/' + PAGE_URL + '/?data=' + dataQuery[1] + ( ACTION ? '&action=' + ACTION : '' ) );
            });  
            
    
            $('#webOPAC__filter .btn-toolbar.activeFilterConditions .btn-group a, #webOPAC__filter .activatedFilterContent .ActivatedFilterItem a').each(function() {
                var hrefUrl = $(this).attr('href');
                var dataQuery = hrefUrl.split("data=");
                $(this).attr('href', WEBOPAC_DOMAIN + '/' + PAGE_URL + '/?data=' + dataQuery[1] + ( ACTION ? '&action=' + ACTION : '' ) );
            });
                
            $('.FilterPanelGroupEntriesWrapper').mCustomScrollbar();
            
            if ( $(window).width() < 480 ) {
                $('#webOPAC__filter').addClass('webOPAC_hide');
                setCookie("filter_status", 'close', 30);
                $('#filterSliderWrapper').mCustomScrollbar({
                    axis:"y",
                });
            } else {
                $('#filterSliderWrapper').mCustomScrollbar({
                    axis:"x",
                });
            }   

            $('#expertFilter').click(function() {
                $(this).toggle();
                $('#simpleFilter').toggle();
                $('#filterSlider').toggle();
                $('#filterDropDown').toggle();
            });
    
            $('#simpleFilter').click(function() {
                $(this).toggle();
                $('#expertFilter').toggle();
                $('#filterDropDown').toggle();
                $('#filterSlider').toggle(); 
            });
        }

    });
}

// user menu
function getUserMenuFromIframe() { 
	$('#targetIframe').attr('src', window.location.origin + '/webopac/user/overview.aspx?referer=MbaT87').addClass('opaciframe-overview-wp');
	if ( !$('.floatting-menu .user-menu ul' ).length ) {
		$('.floatting-menu .wrapper-loading').show();
	};
	$('.opaciframe-overview-wp').bind("load", function() {
		// SubMenu
		$('.floatting-menu .wrapper-loading').hide();
		var iFrameDOM = $( this ).contents();
		var userMenu = iFrameDOM.find('#SubMenu ul');
		$(userMenu).find('a').each(function(){
		var hrefAttr = $(this).attr('href');
		var webopacUrl = hrefAttr.split('.');
		if (typeof webopacUrl[0] !== "undefined") {
			if ( webopacUrl[0] == 'logout' ) {
				$(this).attr('href', '/logout');
			} else {    
				$(this).attr('href', '/overview/?action=' + webopacUrl[0]);
			}
		}            
		});
		if ( !$('.floatting-menu .user-menu ul' ).length ) {
			$('.floatting-menu .user-menu h3').after( $(userMenu) );
		}
	});
}

$( document ).ready(function() {

        $('#opaciframe').iFrameResize([{ log: true }]);
        var iFrames = $('main .opaciframe');

        $(iFrames).on('load', function() {
        
            this.style.height   = parseInt( this.contentWindow.document.body.offsetHeight + 20 ) + 'px';

            var iFrameDOM       = $("mainframe.opaciframe").contents();
            var filtercontent   = iFrameDOM.find('#filtercontent');
            var filterSettings  = iFrameDOM.find('#filterSettings');
            var bullet_toggle   = iFrameDOM.find('#bullet_toggle');

            $( filtercontent ).attr('style','display:none;'); 
            $( filterSettings ).attr('style','display:none;'); 
            $( bullet_toggle ).attr('class','fa toggleFilter fa-plus-square-o'); 

            $(iFrameDOM).click(function() {
                $('#formLoginTop').hide();
            });        
        
            var webopacForm     = iFrameDOM.find('form');
            var actionFormUrl   = $(webopacForm).attr('action');
  
        });
         
        // logout redirect 
        $('iframe#opaciframe-logout').bind("load", function() {
            window.top.location.href = '/';
        });  

        // green box search
         $('iframe#opaciframe').bind("load", function() {
            var iFrameDOM   = $("iframe.opaciframesearch").contents();
            var dataForm    = iFrameDOM.find('#aspnetForm').attr('action');
            if ( dataForm ) {
                var dataQuery = dataForm.split("data=");
                if (typeof dataQuery[1] !== "undefined") {
                    window.top.location.href = '/suchen/?data=' + dataQuery[1];
                    $('.box-search-loading').hide();
                }
            }
         });
         $('#searchwpOK').click(function(){
            var iFrameDOM = $("iframe.opaciframesearch").contents();
            iFrameDOM.find('#ctl00_ContentPlaceHolderMain_searchPanel_txtSearchCriteria1').val( $('#searchwp').val() );
            iFrameDOM.find('#ctl00_ContentPlaceHolderMain_searchPanel_cbxSearchEmedia').prop( "checked", true );
            iFrameDOM.find('#ctl00_ContentPlaceHolderMain_searchPanel_ButtonStartSearch').click();
            $('.box-search-loading').show();
        });
        $('#searchwp_v2').keypress(function(e){
            if(e.which == 13){ // Enter key pressed
                $('.box-search-loading').show();
            }
        });
        $('#searchwpOK_v2').click(function() {
            $('.box-search-loading').show();
            $('#webopac_search').submit();
        });    
        $('#searchBTN').click(function(){
            var iFrameDOM = $("iframe#targetIframe").contents();
            iFrameDOM.find('#ctl00_ContentPlaceHolderMain_ButtonStartSearch').click();
        });         
        //#green box search

        // weboapc Catalogue - search
         var webopacLinks = $( '.webopacCatalogueLink' );
         $( webopacLinks ).each( function() {
            $( this ).click( function() {
                var iframeContent       = $('#opaciframe-' + $(this).data('iframe'));
                var iFrameDOM           = $('iframe.opaciframe').contents();
                var webopacForm         = iFrameDOM.find('form');
                var actionFormUrl       = $(webopacForm).attr('action');
                var targetIframeContent = true;
            
                if ((typeof webopacForm !== "undefined") && (actionFormUrl.indexOf("detail.aspx") > 0)) {
                    targetIframeContent = false;
                }        

                if ((typeof $(iframeContent).attr('id') == "undefined") || (!targetIframeContent)) {
                    $('#targetIframe').attr('src', WEBOPAC_DOMAIN + '/webopac/' + $(this).data('iframe') + '.aspx?referer=MbaT87');
                    $('#targetIframe').attr('data-location', 'wpiframe');
                    webopacCatalogue( 'targetIframe', $(this).data('wrapper'), 'webopac-' + $(this).data('pageurl'), $(this).data('pageurl'), 'wpiframe' );  
                } else {
                    $('#targetIframe').attr('data-location', '');
                    webopacCatalogue( 'opaciframe-' + $(this).data('iframe'), $(this).data('wrapper'), 'webopac-' + $(this).data('pageurl'), $(this).data('pageurl'), '' );  
                    $('.card-body .wrapper-loading-v2').hide();
                }
               
            });
         })
         //#weboapc Catalogue - search         

        // login box 
        $('[class*=wp_buttonLogon]').click(function () {
            var targetForm  = $( '#' + $(this).data('target') );
            var username    = $( targetForm ).find( $('[name*=wp_username]') );
            var password    = $( targetForm ).find( $('[name*=wp_password]') );
            var btnSubmit   = $( this );
            $('#formLoginTop .wrapper-loading-v2').show();
            $.ajax({
                type : 'POST',
                url  : WEBOPAC_DOMAIN + '/webopac/service/UserService.ashx',
                data : ({
                    action:     'userlogin',
                    user:       $(username).val(),
                    password:   $(password).val()
                }),
                contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                success: function (r) {
                    if ( r == 'ok' ) {
                        // 20 minutes - Default idle timeout set in the webopac app
                        setCookieMinutes('logged', '1', 20);
                        var data_wp     = $( '#formLoginTop' ).find( $('#data_wp') ); // popup wp login
                        if (typeof data_wp !== "undefined" && $(data_wp).val() ) {
                            window.location.href = '/overview/?action=reservate&data=' + $(data_wp).val();
                        } else {
                            window.location.href = '/overview/';
                        }
                    } else {
                        $('.dt-login-msg').html(r).show();
                        $('#formLoginTop .wrapper-loading-v2').hide();
                    }
                },
                error: function (r) {
                    alert(r.responseText);
                },
                failure: function (r) {
                    alert(r.responseText);
                }
            });
            return false;
        });

        $( '#wp_password' ).keyup( function( event ) {
            if ( event.keyCode === 13 ) {
                $( '[class*=wp_buttonLogon]' ).click();
            }
        })

        var iFrameHero = $( 'iframe#viewer' ).contents();
        $( iFrameHero ).click( function() {
            $( '#formLoginTop' ).hide();
            $( '#black-overlay-v2' ).removeClass('on');
            $( 'body' ).removeClass('scroll-off');
        });
        $( window ).click( function() {
            $( '#formLoginTop' ).hide();
            $( '#black-overlay-v2' ).removeClass('on');
            $( 'body' ).removeClass('scroll-off');
        });
        $( '#formLoginTop .close' ).click( function() {
            $( '#formLoginTop' ).hide();
            $( '#black-overlay-v2' ).removeClass('on');
            $( 'body' ).removeClass('scroll-off');
        });
        $( '#formLoginTop' ).click( function( event ) {
            event.stopPropagation();
        })
        $('#formLoginTop .eye').click(function() {
            $(this).toggleClass('on');
            $(this).find('i').toggleClass('fa-eye-slash');
            if ( $(this).hasClass('on') ) {
                $('#formLoginTop #wp_password').attr('type','text');
            } else {
                $('#formLoginTop #wp_password').attr('type','password');
            }
        });


        // carouselCustomPost
        $('.carouselCustomPost').slick({
            infinite        : false,
            slidesToShow    : 3,
            dots            : false,
            arrows          : false,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow    : 3,
                    slidesToScroll  : 3,
                    infinite        : true,
                    dots            : true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow    : 2,
                    slidesToScroll  : 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow    : 1.2,
                  }
                }]
          });

        // carouselCustomPost
        $('.customPost_flat .dt-auto-width').slick({
            infinite        : true,
            slidesToShow    : 1,
            slidesToScroll  : 1,
            dots            : false,
            arrows          : false,
            mobileFirst     : true,
            autoplay        : true,
            responsive: [
              {
                breakpoint: 480,
                settings: {
                  autoplaySpeed     : 2000,
                  slidesToShow      : 2.2,
                  slidesToScroll    : 2
                }  
              },
              {
                breakpoint: 768,
                settings    : 'unslick'
            }]
        });

        $('.widget-wrapper-book h4', '.layout-books h3').ellipsis({
           lines       : 2,             
           ellipClass  : 'ellip',  
           responsive  : true      
        });

        $('.widget-wrapper-book h5').ellipsis({
           lines       : 1,             
           ellipClass  : 'ellip',  
           responsive  : true      
        });          

        // on scroll - small header
        var header = $( '.header' );
        $( window ).scroll( function() {    
            var scroll = $( window ).scrollTop();
            if ( scroll >= 500 ) {
               header.addClass( 'header-sm' );
            } else {
               header.removeClass( 'header-sm' );
            }
        });

        function customScrollSlide() {
            $( '.slide-content' ).mCustomScrollbar({
                theme:'dark'
            });
        }


        // TODO: move to webopac js
        $( window ).on( 'load',function() {
            // $( '.FilterPanelGroupEntriesWrapper' ).mCustomScrollbar({
            //     theme:'dark'
            // });
            // // slide cutom scroll
            // if ( $(window).width() > 768 ) {
            //     customScrollSlide();     
            // }            
            // $(window).resize(function () {
            //     if ( $(window).width() > 768 ) {
            //         customScrollSlide();     
            //     } else {
            //         $( '.slide-content' ).mCustomScrollbar('destroy');
            //     }
            // });
        });




        

        $( '.webopac-mobile-popup button' ).click( function(){
            $( '.webopac-mobile-popup' ).hide();
        });
        
        // result list - bottom filter
        $('.webOPAC__filter_label').click(function() {
            var filter_status = getCookie('filter_status');
            if ( filter_status != '' ) {
                if ( filter_status == 'close' ) {
                    $('#webOPAC__filter').removeClass('webOPAC_hide');
                    setCookie("filter_status", '', 30);
                } 
            } else {
                setCookie("filter_status", 'close', 30);
                $('#webOPAC__filter').addClass('webOPAC_hide');
            }

        });
        if ( getCookie('filter_status') == 'close' ) {
            $('#webOPAC__filter').addClass('webOPAC_hide');
        }

        function webopacSearchAutocomplete( textBoxSearchID, submitButtonID ) {
            var autocompleteProviderUrl = WEBOPAC_DOMAIN + "/webopac/service/SearchAutoCompleteProvider.ashx";
            var $textBoxSearch = $( '#' + textBoxSearchID );
            var defaultOptions = {
                serviceUrl: autocompleteProviderUrl,
                minChars: 1,
                zIndex: 2000,
                triggerSelectOnValidInput: false,
                preventBadQueries: false,
                deferRequestBy: 300,
                params: {
                    searchfield: 2,
                },
                source: function(request, response) {
                    $.get( autocompleteProviderUrl, { query: request.term, serachfield: 2 }, function(data) {
                        response(data.suggestions);
                    });
                },
                select: function(suggestion, ui) {
                    $( $textBoxSearch ).val( ui.item.label );
                    $( '#' + submitButtonID ).click();
                }
            }
        
            $textBoxSearch.autocomplete(defaultOptions);
        }
        webopacSearchAutocomplete( 'searchwp_v2', 'searchwpOK_v2' );
        webopacSearchAutocomplete( 'searchwp_tab', 'searchwp_submit_tab' );
        
        $('.hero-slide').slick({
            infinite: true,
            autoplay: true,
            autoplaySpeed: 2000,
            speed: 2000,
            fade: true,
            cssEase: 'linear',
            arrows: true,
            adaptiveHeight: true,
        });
    
        function heroSlide() {
            var slideHeight = $('.hero .slick-slider').height();
            if ( typeof $(slideHeight) != 'undefined' ) {
                if (($(window).height() > 700) && ($(window).width() > 768)) {
                    //slideHeight = slideHeight - 200;
                }
                $('body.home').attr('style', 'padding-top: ' + slideHeight + 'px;' );
            }
        }

        if ( $(window).width() < 768 ) {
            $('#navbarToggler').removeClass('show');
        }

        $(window).resize(function () {
            heroSlide();
            if ( $(window).width() < 480 ) {
                $('#webOPAC__filter').addClass('webOPAC_hide');
                setCookie("filter_status", 'close', 30);
            }
        })
    
        // if ( $(window).width() > 768 ) {
        //     heroSlide();
        // }

        // TODO: JS error
        // $(window).load(function() {
        //     heroSlide();
        // });         

        $('iframe#webopac-login').bind("load", function() {
            var iFrameDOM   = $( 'iframe#webopac-login' ).contents();
            var loginForm   = iFrameDOM.find('#LoginContent');
            if ( !$(loginForm).length ) { 
                setCookieMinutes('logged', '1', 20);
            } else {
                Cookies.remove('logged');
            }
        }); 


});

function setCookieMinutes(cname, cvalue, minutes) {
    var d = new Date();
    d.setTime(d.getTime() + (minutes*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = 'expires='+ d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

function getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
           c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
           return c.substring(name.length, c.length);
        }
    }
    return '';
}

